<div class='container'>
  <div class='logo'>
    <img src='/assets/img/DS-big-logo.png' />
  </div>
  <div class='content'>
    <p>
      Your certificates matter to us. We're temporarily offline to upgrade our platform, enhancing the experience and adding new features. No action is needed - your credentials are safe and will be waiting for you when we return.
    </p>
<!--    <p>-->
<!--      Please note that Diplomasafe will be undergoing system maintenance from 19th of July 7 AM GMT+2 to 19th of July 8-->
<!--      AM GMT+2.-->
<!--    </p>-->
<!--    <br>-->
<!--    <p>-->
<!--      During this short period of time, you will not be able to use the platform.<br>-->
<!--      Normal operations will be restored by the 19th of July at 8 AM GMT+2.-->
<!--    </p>-->
<!--    <br>-->
<!--    <p>-->
<!--      We apologize for the inconvenience caused.-->
<!--    </p>-->

  </div>
</div>


